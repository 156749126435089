import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Constructor from "../components/constructor";
import ClientOnly from "../clientOnly";

function Ipo({ data: { prismicIpo } }) {
  return (
    <>
      <Seo
        title={prismicIpo.data.seo_title}
        description={prismicIpo.data.seo_description}
      />

      <ClientOnly>
        <Layout>
          <Constructor slices={prismicIpo.data.body} />
        </Layout>
      </ClientOnly>
    </>
  );
}

/**
 * Шаблон страниц ipo
 * @module src/templates/page
 * @param {Object} props - объект свойств компонента React
 * @param {Object} props.data - объект данных полученный из prismic
 */
export default Ipo;

export const query = graphql`
  query getIpoData($uid: String!, $lang: String!) {
    prismicIpo(uid: { eq: $uid }, lang: { eq: $lang }) {
      data {
        title
        seo_title
        seo_description
        body {
          ... on PrismicIpoDataBodyTextWithColoredButtons {
            id
            slice_type
            primary {
              full_screen
              background
              background_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              title
              description
              signature
              button_text
              button_link
              link_text
              link_url
            }
            items {
              button_title
              button_subtitle
              button_link
              button_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicIpoDataBodySiteGuide {
            id
            slice_type

            items {
              group_type
              group_title
              group_link_title
              group_link_url
              link_1_title
              link_1_url
              link_1_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              link_2_title
              link_2_url
              link_2_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              link_3_title
              link_3_url
              link_3_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              link_4_title
              link_4_url
              link_4_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              link_5_title
              link_5_url
              link_5_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicIpoDataBodyOnlyText {
            id
            slice_type
            primary {
              full_screen
              background
              background_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              align
              text {
                html
              }
              button_text
              button_link
            }
            items {
              card_text {
                html
              }
              card_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicIpoDataBodyTextWithList {
            id
            slice_type
            primary {
              full_screen
              background
              background_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              align
              text {
                html
              }
            }
            items {
              list_item_text {
                html
              }
              button_text
              button_link
            }
          }
          ... on PrismicIpoDataBodyCardSet {
            id
            slice_type
            items {
              accent
              text {
                html
              }
              link_text
              link_url
              button_text
              button_link
              img {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicIpoDataBodyCardSetV2 {
            id
            slice_type
            items {
              text {
                html
              }
              img {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicIpoDataBodyTextWithPictureOnTheSide {
            id
            slice_type
            primary {
              full_screen
              background
              background_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              content_on_the_left
              text {
                html
              }
              button_text
              button_link
              link_text
              link_url
              img {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            items {
              excerption_description
              excerption_title
            }
          }
          ... on PrismicIpoDataBodyTextWithPictureAndList {
            id
            slice_type
            primary {
              full_screen
              background
              background_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              content_on_the_left
              text {
                html
              }
              img {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            items {
              list_item_text {
                html
                text
              }
              list_item_img {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicIpoDataBodyBlockListTextWithPicture {
            id
            slice_type
            primary {
              full_screen
              background
              background_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            items {
              text {
                html
              }
              button_text
              button_link
              link_text
              link_url
              img {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicIpoDataBodyTariffCards {
            id
            slice_type
            primary {
              text {
                html
              }
            }
            items {
              subtitle
              title
              description
              left_column {
                html
              }
              right_column {
                html
              }
              link_text
              link_url
              button_text
              button_link
              background {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicIpoDataBodyAccountOpeningForm {
            id
            slice_type
            primary {
              full_screen
              background
              background_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              text {
                html
              }
              first_name
              first_name_placeholder
              last_name
              last_name_placeholder
              email_title
              email_placeholder
              phone_title
              phone_placeholder
              password_title
              password_placeholder
              button_text
              link_text
              link_url
            }
          }
          ... on PrismicIpoDataBodyAccordion {
            id
            slice_type
            primary {
              text {
                html
              }
            }
            items {
              accordion_title
              accordion_description {
                html
              }
              button_text
              button_link
            }
          }
          ... on PrismicIpoDataBodyTextAndTwoCardsWithText {
            id
            slice_type
            primary {
              align
              title_text {
                html
              }
            }
            items {
              align
              card_text {
                html
              }
              button_text
              button_link
            }
          }
          ... on PrismicIpoDataBodyCardsIpo {
            id
            slice_type
            primary {
              check_type
              check_status
              link_text
              button_text
              button_link
              bottom_button_type
              bottom_button_text
              bottom_button_link
            }
            items {
              document_ipo {
                document {
                  ... on PrismicIpo {
                    uid
                    data {
                      title
                      type
                      status
                      logo {
                        alt
                        localFile {
                          extension
                          publicURL
                          childImageSharp {
                            gatsbyImageData
                          }
                        }
                      }
                      text_expected_pre_ipo {
                        html
                      }
                      text_expected_ipo {
                        html
                      }
                      text_actively_pre_ipo {
                        html
                      }
                      text_actively_ipo {
                        html
                      }
                      result_title_pre_ipo
                      result_pre_ipo
                      text_passed_pre_ipo {
                        html
                      }
                      result_title_ipo
                      result_ipo
                      text_passed_ipo {
                        html
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicIpoDataBodyTariffCardsFromDocuments {
            id
            slice_type
            items {
              link_text
              link_url
              button_text
              button_link
              tariff {
                document {
                  ... on PrismicTariff {
                    id
                    uid
                    data {
                      subtitle
                      name
                      description {
                        html
                      }
                      body {
                        ... on PrismicTariffDataBodyHeader {
                          id
                          slice_type
                          primary {
                            header {
                              html
                            }
                          }
                        }
                        ... on PrismicTariffDataBodyGroupValue {
                          id
                          slice_type
                          primary {
                            group_type
                            group_title {
                              html
                            }
                          }
                          items {
                            regular_title {
                              html
                            }
                            regular_subtitle {
                              html
                            }
                            accent_color
                            accent_title {
                              html
                            }
                            accent_subtitle {
                              html
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicIpoDataBodyTextAndTwoCardsTextAndList {
            id
            slice_type
            primary {
              text {
                html
              }
              align
              left_card_text {
                html
              }
              left_button_text
              left_button_link
              right_card_text {
                html
              }
              right_button_text
              right_button_link
              list_on_left
            }
            items {
              title
              value
            }
          }
          ... on PrismicIpoDataBodyContacts {
            id
            slice_type
            primary {
              title
            }
            items {
              card_title
              card_description {
                html
              }
              image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              contact {
                html
              }
            }
          }
          ... on PrismicIpoDataBodyIpoCatalog {
            id
            slice_type
            primary {
              check_type
              check_status
              link_text
              button_text
              button_link
            }
          }
          ... on PrismicIpoDataBodyBreadcrumbs {
            id
            slice_type
            items {
              link_title
              link_url
            }
          }
          ... on PrismicIpoDataBodyHeaderWithIndicators {
            id
            slice_type
            primary {
              title
              title_column_1
              value_column_1
              title_column_2
              value_column_2
              title_column_3
              value_column_3
              title_column_4
              value_column_4
              image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              title_additional_column_1
              value_additional_column_1
              title_additional_column_2
              value_additional_column_2
              link_text
              link_url
              button_text
              button_link
            }
            items {
              tag
            }
          }
          ... on PrismicIpoDataBodyNews {
            id
            slice_type
            items {
              accent
              key_text
              rich_text {
                html
                text
              }
              key_date
            }
            primary {
              main_title {
                html
                text
              }
            }
          }
        }
      }
    }
  }
`;
